// @ts-check
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApi } from "../shared/useApi";

export const FILES = "files";

/** @type {() => import("react-query").UseMutationResult<void, unknown, Blob & { oid: string }>} */
export const useUploadFileMutation = () => {
  const api = useApi();
  return useMutation(async (file) => {
    await api.put(`v1/files/${file.oid}`, {
      body: await file.arrayBuffer(),
    });
  });
};

/** @type {() => import("react-query").UseMutationResult<void, unknown, Blob & { oid: string }>} */
export const useDeleteFileMutation = () => {
  const api = useApi();
  return useMutation(async (oid) => {
    await api.delete(`v1/files/${oid}`);
  });
};

/** @typedef {{ format?: "avif" | "webp" | "jpeg", width?: number, height?: number }} DownloadFileSearchParams */

/** @type {() => (oid: string, type: string, searchParams?: DownloadFileSearchParams) => Promise<Blob>} */
export const useDownloadFile = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return async (oid, type, searchParams = {}) => {
    return queryClient.fetchQuery({
      queryKey: [FILES, oid, type, searchParams],
      queryFn: async () => {
        const content = await api
          .get(`v1/files/${oid}`, {
            searchParams,
          })
          .arrayBuffer();
        const blob = new Blob([content], { type });
        return blob;
      },
      staleTime: Infinity,
    });
  };
};

/** @type {(blob: Blob) => Promise<string>} */
const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(/** @type {string} */ (reader.result));
    };
  });
};

/** @type {() => (oid: string, type: string, searchParams?: DownloadFileSearchParams) => Promise<string>} */
const useDownloadBase64 = () => {
  const downloadFile = useDownloadFile();
  return async (oid, type, searchParams = {}) => {
    const blob = await downloadFile(oid, type, searchParams);
    const base64 = await blobToBase64(blob);
    return base64;
  };
};

/** @type {() => (oid: string, type: string, searchParams?: DownloadFileSearchParams) => Promise<void>} */
export const usePrefetchDownloadBase64 = () => {
  const downloadBase64 = useDownloadBase64();
  const queryClient = useQueryClient();
  return async (oid, type, searchParams) => {
    return queryClient.prefetchQuery({
      queryKey: ["files-base64", oid, type, searchParams],
      queryFn: async () => downloadBase64(oid, type, searchParams),
      staleTime: Infinity,
    });
  };
};

/** @type {(oid: string, type: string, searchParams?: DownloadFileSearchParams) => import("react-query").UseQueryResult<string>} */
export const useDownloadBase64Query = (oid, type, searchParams) => {
  const downloadBase64 = useDownloadBase64();
  return useQuery({
    queryKey: ["files-base64", oid, type, searchParams],
    queryFn: async () => downloadBase64(oid, type, searchParams),
    staleTime: Infinity,
  });
};
